body {
    margin: 0;
    background-color: #525659;
    font-family: Segoe UI, Tahoma, sans-serif;
}

.Pdf-viewer__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0px auto;
    padding: 10px;
}


.Pdf-viewer__container__document {
    width: 100%;
    max-width: calc(100% - 2em);
    margin: 0px 0;
}

.Pdf-viewer__container__document .react-pdf__Document {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.Pdf-viewer__container__document .react-pdf__Page {
    margin: 0px 0;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
}

.Pdf-viewer__container__document .react-pdf__message {
    padding: 20px;
    color: white;
}